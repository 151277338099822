import './SchedulerTopbar.scss';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

SchedulerTopbar.propTypes = {
  activeYear: PropTypes.number.isRequired,
  activeMonth: PropTypes.number.isRequired,
  changeMonth: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

function SchedulerTopbar({ activeYear, activeMonth, changeMonth, openModal }) {
  return (
    <nav className='scheduler-topbar px-md-4 d-flex justify-content-around align-items-center'>
      <div className='month-selector mx-md-2 d-flex'>
        <button
          className='btn'
          onClick={() => {
            changeMonth(-1);
          }}
        >
          Prev
        </button>
        <span className='active-month align-self-center'>
          {activeMonth} - {activeYear}
        </span>
        <button
          className='btn'
          onClick={() => {
            changeMonth(1);
          }}
        >
          Next
        </button>
      </div>
      <div className='mx-md-2'>
        <FontAwesomeIcon icon={faPlus} className='add-icon mr-4' onClick={openModal} />
      </div>
    </nav>
  );
}

export default SchedulerTopbar;
