import { useEffect, useState } from 'react';
import './Calendar.scss';
import PropTypes from 'prop-types';
import { isTournamentInDay, guessForegroundColor, formatDate } from '../../utils/common';
import { constants } from '../../constants';

Calendar.propTypes = {
  days: PropTypes.array.isRequired,
  tournamentList: PropTypes.array.isRequired,
  openEditModal: PropTypes.func.isRequired,
};

function Calendar({ days, tournamentList, openEditModal }) {
  const [remainingSlots, setRemainingSlots] = useState([]);
  const [startingSlots, setStartingSlots] = useState([]);

  function truncateDay(name) {
    if (window.innerWidth <= 768) {
      return name.substring(0, 3);
    }

    return name;
  }

  function truncateName(name) {
    if (window.innerWidth <= 768 && name.length > 6) {
      return name.substring(0, 4) + '...';
    }

    return name;
  }

  // set empty slot arrays
  useEffect(() => {
    let startingSlotList = [];
    const firstMonday = days.find((x) => x.name === 'lunedì');
    if (firstMonday && firstMonday.number !== 1) {
      const startingSlotNumber = 8 - firstMonday.number;
      for (let i = 0; i < startingSlotNumber; i++) {
        startingSlotList.push(
          <div className='day empty' key={'remaining_' + i}>
            <div className='day-content d-flex flex-column align-items-center'></div>
          </div>
        );
      }
    }
    setStartingSlots(startingSlotList);

    let remainingSlotList = [];
    const remainingSlotNumber = 7 - ((days.length + startingSlotList.length) % 7);

    for (let i = 0; i < remainingSlotNumber; i++) {
      remainingSlotList.push(
        <div className='day empty' key={'remaining_' + i}>
          <div className='day-content d-flex flex-column align-items-center'></div>
        </div>
      );
    }
    setRemainingSlots(remainingSlotList);
  }, [days]);

  return (
    <div className='Calendar row g-0 m-0'>
      <div className='title-bar d-flex'>
        {constants.dayNames.map((day, i) => (
          <div
            key={'day_' + i}
            className='day-title d-flex justify-content-center align-items-center'
          >
            <span>{truncateDay(day)}</span>
          </div>
        ))}
      </div>
      <div className='days row p-0 g-0'>
        {startingSlots}
        {days.map((day) => (
          <div className='day' key={day.number}>
            <div className='day-content d-flex flex-column align-items-center'>
              <div
                className={'day-number' + (day.fullDate === formatDate(new Date()) ? ' today' : '')}
              >
                {day.number}
              </div>
              <div className='tournamentList'>
                {tournamentList.map(
                  (tournament) =>
                    isTournamentInDay(tournament, day) && (
                      <div
                        className='tournament-name'
                        style={{
                          backgroundColor: tournament.Color,
                          color: guessForegroundColor(tournament.Color),
                        }}
                        key={tournament.Name}
                        onClick={() => openEditModal(tournament)}
                      >
                        {truncateName(tournament.Name)}
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        ))}
        {remainingSlots}
      </div>
    </div>
  );
}

export default Calendar;
