import { http } from './axios';

/* get all tournaments */
export const getTournaments = (httpConfig = {}) => {
  const url = '/API/tournaments.php';

  return http.get(url, httpConfig);
};

/* create new tournaments */
export const createTournament = (tournamentParams, httpConfig = {}) => {
  const url = '/API/tournaments.php';

  return http.post(url, tournamentParams, httpConfig);
};

/* edit tournaments */
export const editTournament = (tournamentParams, httpConfig = {}) => {
  const url = '/API/tournaments.php';

  return http.put(url, tournamentParams, httpConfig);
};

/* delete tournaments */
export const deleteTournament = (id, httpConfig = {}) => {
  const url = `/API/tournaments.php/?id=${id}`;

  return http.delete(url, httpConfig);
};
