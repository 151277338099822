import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';

import Scheduler from './views/scheduler/Scheduler';
import Player from './views/player/Player';

function App() {
  return (
    <div className='App'>
      <main className='main-content'>
        <Routes>
          <Route path='/scheduler' element={<Scheduler />} exact />
          {/* <Route path='/player' element={<Player />} exact /> */}
          <Route path='*' element={<Navigate to='/scheduler' replace />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
