import { useEffect, useState } from 'react';
import './Scheduler.scss';

import {
  getTournaments,
  createTournament,
  editTournament,
  deleteTournament,
} from '../../services/api';
// import DesktopHeader from '../../components/desktopHeader/DesktopHeader';
import SchedulerTopbar from '../../components/schedulerTopbar/SchedulerTopbar';
import Calendar from '../../components/calendar/Calendar';
import TournamentModal from '../../components/tournamentModal/TournamentModal';
import { formatDate } from '../../utils/common';
import { useSwipeable } from 'react-swipeable';

function Scheduler() {
  const [tournamentList, setTournamentList] = useState([]);
  const [activeYear, setActiveYear] = useState(null);
  const [activeMonth, setActiveMonth] = useState(null);
  const [daysNumber, setDaysNumber] = useState(null);
  const [days, setDays] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [activeTournament, setActiveTournament] = useState(null);

  // listen for swipe events
  const handlers = useSwipeable({
    onSwiped: (eventData) => handleSwipe(eventData),
  });

  // change month on swipe left or right
  function handleSwipe(eventData) {
    if (eventData.deltaY < 50 && eventData.deltaY > -50) {
      switch (eventData.dir) {
        case 'Left':
          changeMonth(1);

          break;

        case 'Right':
          changeMonth(-1);

          break;

        default:
          break;
      }
    }
  }

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  function getDayName(day) {
    var date = new Date(activeYear + '/' + activeMonth + '/' + day);

    return date.toLocaleDateString('it-IT', { weekday: 'long' });
  }

  function changeMonth(index) {
    const newMonth = activeMonth + index;
    switch (newMonth) {
      case 13:
        setActiveYear(activeYear + 1);
        setActiveMonth(1);

        break;

      case 0:
        setActiveYear(activeYear - 1);
        setActiveMonth(12);

        break;

      default:
        setActiveMonth(newMonth);

        break;
    }
  }

  function closeModal() {
    // close modal and set err to null
    setActiveTournament(null);
    setError(null);
    setShowModal(false);
  }

  function openModal() {
    // open modal
    setShowModal(true);
  }

  function openEditModal(tournament) {
    setActiveTournament(tournament);
    openModal();
  }

  // add tournament
  function onAddTournament(params) {
    // set tournament params
    const newTournament = {
      Name: params.name,
      StartDate: formatDate(params.startDate),
      EndDate: formatDate(params.endDate),
      Color: params.color,
      Link: params.link,
    };

    // try to create tournament on db
    createTournament(newTournament)
      .then(() => {
        getTournaments()
          .then((tournaments) => {
            setTournamentList(tournaments.data.sort(sortByDate));
          })
          .catch((error) => {
            console.error(error);
          });
        closeModal();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  // edit tournament
  function onEditTournament(params) {
    // set tournament params
    const tournament = {
      Id: params.id,
      Name: params.name,
      StartDate: formatDate(params.startDate),
      EndDate: formatDate(params.endDate),
      Color: params.color,
      Link: params.link,
    };

    // try to create tournament on db
    editTournament(tournament)
      .then(() => {
        getTournaments()
          .then((tournaments) => {
            setTournamentList(tournaments.data.sort(sortByDate));
          })
          .catch((error) => {
            console.error(error);
          });
        closeModal();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  // delete tournament
  function onDeleteTournament(id) {
    // try to create tournament on db
    deleteTournament(id)
      .then(() => {
        getTournaments()
          .then((tournaments) => {
            setTournamentList(tournaments.data.sort(sortByDate));
          })
          .catch((error) => {
            console.error(error);
          });
        closeModal();
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  }

  // sort tournaments by start date
  function sortByDate(a, b) {
    if (a.StartDate < b.StartDate) {
      return -1;
    }
    if (a.StartDate > b.StartDate) {
      return 1;
    }
    if (a.EndDate < b.EndDate) {
      return -1;
    }
    if (a.EndDate > b.EndDate) {
      return 1;
    }

    return 0;
  }

  // get tournament array
  useEffect(() => {
    // set current day if null
    if (activeYear === null || activeMonth === null) {
      const date = new Date();
      const currentYear = date.getFullYear();
      const currentMonth = date.getMonth() + 1;
      setActiveYear(currentYear);
      setActiveMonth(currentMonth);
    }

    // 👇️ get number of days
    const daysInCurrentMonth = getDaysInMonth(activeYear, activeMonth);
    setDaysNumber(daysInCurrentMonth);

    getTournaments()
      .then((tournaments) => {
        setTournamentList(tournaments.data.sort(sortByDate));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [activeMonth, activeYear]);

  // set daysArray
  useEffect(() => {
    let daysArray = [];
    let names = [];
    for (let i = 1; i <= daysNumber; i++) {
      let name = getDayName(i);
      daysArray.push({
        number: i,
        name: name,
        fullDate: `${activeYear}/${activeMonth}/${i}`,
      });
      if (i <= 7) {
        names.push({ name: name });
      }
    }

    setDays(daysArray);
  }, [daysNumber, activeMonth, activeYear]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {activeMonth && (
        <div className='scheduler' {...handlers}>
          {/* <DesktopHeader> */}
          <SchedulerTopbar
            activeYear={activeYear}
            activeMonth={activeMonth}
            changeMonth={changeMonth}
            openModal={openModal}
          />
          {/* </DesktopHeader> */}
          <Calendar days={days} tournamentList={tournamentList} openEditModal={openEditModal} />
        </div>
      )}
      {showModal && (
        <TournamentModal
          onClose={closeModal}
          addTournament={onAddTournament}
          editTournament={onEditTournament}
          deleteTournament={onDeleteTournament}
          error={error || null}
          tournament={activeTournament}
        />
      )}
    </>
  );
}

export default Scheduler;
