import { useEffect, useState } from 'react';
import './TournamentModal.scss';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useFormInput, useOutsideClick } from '../../utils/common';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

TournamentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  addTournament: PropTypes.func.isRequired,
  editTournament: PropTypes.func.isRequired,
  deleteTournament: PropTypes.func.isRequired,
  error: PropTypes.number,
  tournament: PropTypes.object,
};

function TournamentModal({
  onClose,
  addTournament,
  editTournament,
  deleteTournament,
  error,
  tournament,
}) {
  const [ranges, setRanges] = useState([
    {
      startDate: tournament ? new Date(tournament.StartDate) : new Date(),
      endDate: tournament ? new Date(tournament.EndDate) : new Date(),
      key: 'selection',
    },
  ]);
  const [months, setMonths] = useState(2);
  const [showLink, setShowLink] = useState(tournament && tournament.Link);

  const tournamentName = useFormInput(tournament ? tournament.Name : '');
  const tournamentColor = useFormInput(tournament ? tournament.Color : '');
  const tournamentLink = useFormInput(tournament ? tournament.Link : '');

  function onAddTournament() {
    addTournament({
      name: tournamentName.value,
      color: tournamentColor.value || '#eee',
      startDate: ranges[0].startDate,
      endDate: ranges[0].endDate,
      link: tournamentLink.value,
    });
  }

  function onEditTournament() {
    editTournament({
      id: tournament.Id,
      name: tournamentName.value,
      color: tournamentColor.value || '#eee',
      startDate: ranges[0].startDate,
      endDate: ranges[0].endDate,
      link: tournamentLink.value,
    });
  }

  function onDeleteTournament() {
    deleteTournament(tournament.Id);
  }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setMonths(window.innerWidth <= 768 ? 1 : 2);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();

    // remove event listener on dismount
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const ref = useOutsideClick(() => {
    onClose();
  });

  return (
    <div className='tournament-modal'>
      <div id='modal-bkg' className='row justify-content-center align-items-center h-100 g-0'>
        <div className='card px-md-5'>
          <div className='text-end px-4 px-md-0'>
            <FontAwesomeIcon icon={faTimes} className='close-icon mr-4 mt-4' onClick={onClose} />
          </div>
          <form className='box' ref={ref}>
            <h1>{tournament ? 'Modifica torneo' : 'Aggiungi torneo'}</h1>
            <div className='d-md-flex justify-content-center pt-2'>
              <div className='mx-2'>
                <span>Nome torneo</span>
                <input
                  className='form-control text-input'
                  type='text'
                  {...tournamentName}
                  autoComplete='new-name'
                  placeholder='Nome torneo'
                />
              </div>
              <div className='mx-md-2'>
                <span>Colore torneo</span>
                <input
                  className='form-control color-picker'
                  type='color'
                  {...tournamentColor}
                  autoComplete='new-color'
                  placeholder='Colore torneo'
                />
              </div>
            </div>
            {tournamentLink && showLink ? (
              <div className='d-flex justify-content-center pt-2'>
                <div>
                  <a
                    className='tournament-link'
                    href={tournamentLink.value}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Link torneo
                  </a>
                </div>
                <div className='edit-link-icon-container'>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className='edit-link-icon'
                    onClick={() => setShowLink(false)}
                  />
                </div>
              </div>
            ) : (
              <div className='d-md-flex justify-content-center pt-2'>
                <div className='w-50 mx-auto'>
                  <span>Link torneo</span>
                  <input
                    className='form-control'
                    type='url'
                    {...tournamentLink}
                    autoComplete='new-link'
                    placeholder='Link torneo'
                  />
                </div>
              </div>
            )}
            <div className='datepicker mt-4'>
              <DateRangePicker
                onChange={(item) => setRanges([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                staticRanges={[]}
                inputRanges={[]}
                months={months}
                ranges={ranges}
                direction='horizontal'
              />
            </div>
            {error && (
              <>
                <small style={{ color: 'red' }}>{error}</small>
              </>
            )}
            <button
              className='submit-button mt-4'
              type='button'
              name='submit'
              onClick={tournament ? onEditTournament : onAddTournament}
            >
              {tournament ? <span>Modifica Torneo</span> : <span>Aggiungi torneo</span>}
            </button>
            {tournament && (
              <button
                className='delete-button mt-4'
                type='button'
                name='delete'
                onClick={onDeleteTournament}
              >
                <span>Elimina torneo</span>
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default TournamentModal;
