import { useEffect, useState, useRef } from 'react';

/* get api base url */
export const getBaseUrl = () => {
  return window.location.origin.startsWith('http://localhost') ? 'http://localhost/scheduler' : '';
};

/* check if day has tournaments */
export const isTournamentInDay = (tournament, day) => {
  return (
    new Date(tournament.StartDate.replace(/-/g, '/')).setHours(0, 0, 0, 0) <=
      new Date(day.fullDate.replace(/-/g, '/')).setHours(0, 0, 0, 0) &&
    new Date(tournament.EndDate.replace(/-/g, '/')).setHours(0, 0, 0, 0) >=
      new Date(day.fullDate.replace(/-/g, '/')).setHours(0, 0, 0, 0)
  );
};

export const guessForegroundColor = (bgHex) => {
  bgHex = String(bgHex).replace(/[^0-9a-f]/gi, '');
  if (bgHex.length < 6) {
    bgHex = bgHex[0] + bgHex[0] + bgHex[1] + bgHex[1] + bgHex[2] + bgHex[2];
  }
  var rgb = parseInt(bgHex, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma > 164 ? '#000' : '#fff';
};

// use form
export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const reset = () => {
    setValue(initialValue);
  };

  return {
    value,
    onChange: handleChange,
    onReset: reset,
  };
};

// format date for db
export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return year + '/' + month + '/' + day;
};

export const useOutsideClick = (callback) => {
  const ref = useRef(null);

  const handleClick = (e) => {
    if (ref.current && e.target.id === 'modal-bkg') {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  });

  return ref;
};
